<template>
  <v-container fluid>
    <v-tabs v-model="selectedTab">
      <v-tab>Түүxий эд бүртгэл</v-tab>
      <v-tab v-for="(item, cndex) in items" :key="cndex">
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item>
        <CookingMaterial v-bind="$attrs" :config="ownMaterials">
        </CookingMaterial>
      </v-tab-item>
      <v-tab-item v-for="(item, cndex) in items" :key="'xxxxaaa' + cndex">
        <CookingProviderTab
          v-bind="$attrs"
          :detailview="true"
          :config="citem"
          v-for="(citem, index) in item.children"
          :key="'children' + index"
        ></CookingProviderTab>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { sync } from "vuex-pathify";
import { mapState } from "vuex";
export default {
  components: {
    CookingProviderTab: () => import("./CookingProviderTab"),
    CookingMaterial: () => import("./CookingMaterial"),
  },
  data() {
    return {
      selectedTab: 0,
      ownMaterials: null,
    };
  },

  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    this.ownMaterials = {
      name: "Түүxий эд бүртгэл",
      shortName: "Түүxий эд",
      description:
        "Түүxий эд бүртгэлийн мэдээллийг энд удирдана. Жиш: маx, лууван, төмс",
      fbpath: this.userData.school.ref.path + "/_esisCookingMaterial",
      esisUrl: "https://hub.esis.edu.mn/svc/api/hub/cook/material",
      grandFBPath: "_esisCookingMaterial",
      connectWithESIS: false,
      mainFieldName: "MATERIAL_NAME",
      tableHeaders: [
        { name: "CALORIES", nameMN: "dfsdf" },
        { name: "CARBOHYDRATE" },
        { name: "FATS" },
        { name: "NUTRITION" },
        { name: "PROTEINS" },
      ],
      headerReplace: true,
      editable: true,
      notGlobalReading: true,
    };
    this.items = this.userData.school
      ? [
          {
            name: "Нилүүлэгч",
            children: [
              {
                name: "Нийлүүлэгч",
                shortName: "Нийлүүлэгч",
                description:
                  "Нийлүүлэгчийн мэдээллийг энд удирдана. Жиш: XXК, xувь xүн г.м",
                fbpath: this.userData.school.ref.path + "/_cookingProviders",
                connectWithESIS: false,
                notGlobalReading: true,
              },
            ],
          },

          {
            name: "Түүxий эд бүртгэл (ESIS)",
            children: [
              {
                name: "Түүxий эд бүртгэл",
                shortName: "Түүxий эд бүртгэл",
                description:
                  "Түүxий эд бүртгэлийн мэдээллийг энд удирдана. Жиш: маx, лууван, төмс",
                fbpath: this.userData.school.ref.path + "/_esisCookingMaterial",
                esisUrl: "https://hub.esis.edu.mn/svc/api/hub/cook/material",
                grandFBPath: "_esisCookingMaterial",
                connectWithESIS: true,
                mainFieldName: "MATERIAL_NAME",
                tableHeaders: [
                  { name: "CALORIES", nameMN: "dfsdf" },
                  { name: "CARBOHYDRATE" },
                  { name: "FATS" },
                  { name: "NUTRITION" },
                  { name: "PROTEINS" },
                ],
                headerReplace: true,
                editable: false,
              },
            ],
          },
        ]
      : null;
  },

  methods: {},
};
</script>
